import {getFAQs, getAdminFAQs} from "../services/faqs";

export function faqDataEffect(setFAQs) {
  return () => {
    let mounted = true;
    getFAQs()
      .then(faqs => {
        if (mounted) {
          setFAQs(faqs)
        }
      }, err => {
        console.error(err)
      })
    return () => mounted = false;
  }
}

export function faqAdminDataEffect(token, setFAQs) {
  return () => {
    let mounted = true;
    getAdminFAQs(token)
      .then(faqs => {
        if (mounted) {
          setFAQs(faqs)
        }
      }, err => {
        console.error(err)
      })
    return () => mounted = false;
  }
}
