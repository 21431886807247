import BASE_URL from "./const";

export async function getFAQs() {
  return fetch(`${BASE_URL}/faq`).then(data => data.json())
}

export async function getAdminFAQs(token) {
  return fetch(`${BASE_URL}/faq/admin`, {
    headers: {
      'Accept': 'application/json',
      "authorization": `Bearer ${token}`
    }
  }).then(data => data.json())
}

export async function addFAQ(token, faq) {
  return fetch(`${BASE_URL}/faq`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify(faq)
  }).then(data => data.json())
}

export async function updateFAQ(token, faq) {
  return fetch(`${BASE_URL}/faq/${faq.id}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify(faq)
  }).then(data => data.json())
}

export async function deleteFAQ(token, id) {
  return fetch(`${BASE_URL}/faq/${id}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      "authorization": `Bearer ${token}`
    },
  })
}
