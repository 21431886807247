import React, {useEffect, useState} from "react"
import {faqDataEffect} from "../effects/faqData";
import MarkdownEditor from '@uiw/react-markdown-editor'

function FAQ() {
  const [faqs, setFAQs] = useState([])
  useEffect(faqDataEffect(setFAQs), [])

  return (
      <div className="FAQ">
        <h3>Frequently Asked Questions</h3>
        <div className="flex-table">
          <div className="flex-table-row faq-row">
            <div className="flex-table-cell flex-table-header"><h4>Question</h4></div>
            <div className="flex-table-cell flex-table-header"><h4>Answer</h4></div>
          </div>
          {faqs.filter(faq => faq.active).map(row =>
            <div className="flex-table-row faq-row">
              <div className="flex-table-cell"><b>{row.question}</b></div>
              <div className="flex-table-cell">
                <MarkdownEditor.Markdown source={row.answer} style={
                  {
                    color: '#000000',
                    backgroundColor: '#FFFFFF'
                  }
                }/>
              </div>
            </div>
          )}
        </div>
      </div>
    );
}

export default FAQ;
