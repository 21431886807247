import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Button, OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

export function RowLock(props) {
  const {rowsLocked, setRowsLocked, additionalClassNames} = props

  return (
    <div className={"flex-table-cell "+additionalClassNames}>
      <ButtonGroup aria-label="Lock Rows">
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-lock-rows`}>
              Lock Rows so they don't move.
            </Tooltip>
          }
        >
          <Button className="btn-danger" onClick={() => setRowsLocked(true)} disabled={rowsLocked}>
            <mwc-icon>lock</mwc-icon>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-unlock-rows`}>
              Unlock Rows so they can be moved for reordering.
            </Tooltip>
          }
        >
          <Button className="btn-success" onClick={() => setRowsLocked(false)} disabled={!rowsLocked}>
            <mwc-icon>lock_open</mwc-icon>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </div>
  )
}
