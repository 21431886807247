import BASE_URL from "./const";

export function getAllPageData(token, pageName) {
  return fetch(`${BASE_URL}/page/data/${pageName}/all`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
  })
    .then(data => data.json())
}

export function getPageData(pageName) {
  return fetch(`${BASE_URL}/page/data/${pageName}`)
    .then(data => data.json())
}

export function saveMixedChanges(token, addedRows, editedRows) {
  const promises = []
  editedRows.forEach(row => promises.push(updatePageData(token, row)))
  if (addedRows.length) {
    promises.push(createPageData(token, addedRows))
  }
  return promises
}

export function createPageData(token, rows) {
  rows.forEach(r => r.id = undefined)
  return fetch(`${BASE_URL}/page/data`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify(rows)
  }).then(data => data.json())
}

export function updatePageData(token, row) {
  return fetch(`${BASE_URL}/page/data/${row.id}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify(row)
  }).then(data => data.json())
}

export function deletePageData(token, rowId) {
  return fetch(`${BASE_URL}/page/data/${rowId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    }
  })
}
