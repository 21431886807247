import React, {useState} from "react"
import {Button} from "react-bootstrap"

function ShortTermDataRow(props) {
  const {lot, addedLots, setAddedLots, editedLots, setEditedLots, deleteRow} = props
  const [edited, setEditedValue] = useState(
    addedLots.map(l => l.id).includes(lot.id) ?
      addedLots.find(l => l.id === lot.id) :
      editedLots.map(l => l.id).includes(lot.id) ?
        editedLots.find(l => l.id === lot.id) :
        lot)

  const edit = lot.id < 0 || (editedLots && editedLots.map(l => l.id).includes(lot.id))

  const setEdited = (newVal) => {
    if (newVal.id > 0) {
      setEditedLots([
        ...editedLots.filter(l => l.id !== newVal.id),
        newVal
      ])
    } else {
      setAddedLots([
        ...addedLots.filter(l => l.displayOrder !== newVal.displayOrder),
        newVal
      ])
    }
    setEditedValue(newVal)
  }

  return (
    <div className={edit ? 'flex-table-row flex-table-edited-row' : 'flex-table-row'}>
      <div className="flex-table-cell flex-table-cell-md">
        <div>
          <input size='2' maxLength='2' value={edited.lotLetter}
                 onChange={e => setEdited({...edited, lotLetter: e.target.value})}/>
          <br/>
          <input size='3' maxLength='3' value={edited.lotNumber}
                 onChange={e => setEdited({...edited, lotNumber: e.target.value})}/>
        </div>
      </div>
      <div className="flex-table-cell">
        <textarea maxLength='500' value={edited.lotAddress} rows="3" cols="15" style={{resize: 'both'}}
                  onChange={e => setEdited({...edited, lotAddress: e.target.value})}/>
      </div>
      <div className="flex-table-cell">
        <textarea maxLength='1000' value={edited.addressNote ? edited.addressNote : ''} rows="3"
                  style={{resize: 'both'}}
                  cols="15" onChange={e => setEdited({...edited, addressNote: e.target.value})}/>
      </div>
      <div className="flex-table-cell">
        <textarea maxLength='2000' value={edited.note ? edited.note : ''} rows="3" cols="15" style={{resize: 'both'}}
                  onChange={e => setEdited({...edited, note: e.target.value})}/>
      </div>
      <div className="flex-table-cell">
        <textarea maxLength='1000' value={edited.urgentNote ? edited.urgentNote : ''} rows="3" style={{resize: 'both'}}
                  cols="15" onChange={e => setEdited({...edited, urgentNote: e.target.value})}/>
      </div>
      <div className="flex-table-cell flex-table-cell-md">
        <div>
        <span className={edited.textToPay ? 'enabled' : 'disabled'} onClick={() => setEdited({
          ...edited, textToPay: !edited.textToPay
        })}>
          <mwc-icon>sms</mwc-icon>
        </span>
          <span className={edited.hourlyRates ? 'enabled' : 'disabled'} onClick={() => setEdited({
            ...edited, hourlyRates: !edited.hourlyRates
          })}>
          <mwc-icon>hourglass_full</mwc-icon>
        </span>
          <span className={edited.flatRates ? 'enabled' : 'disabled'} onClick={() => setEdited({
            ...edited, flatRates: !edited.flatRates
          })}>
          <mwc-icon>local_atm</mwc-icon>
        </span>
          <span className={edited.limitedHours ? 'enabled' : 'disabled'} onClick={() => setEdited({
            ...edited, limitedHours: !edited.limitedHours
          })}>
          <mwc-icon>av_timer</mwc-icon>
        </span>
          <span className={edited.active ? 'enabled' : 'disabled'} onClick={() => setEdited({
            ...edited, active: !edited.active
          })}>
          <mwc-icon>remove_red_eye</mwc-icon>
        </span>
          <Button className='btn-danger' onClick={() => deleteRow(lot)}>
            <mwc-icon>delete</mwc-icon>
          </Button>
        </div>
      </div>
    </div>)
}

export default ShortTermDataRow
