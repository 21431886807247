import React, {useEffect, useState} from "react"
import {allPageDataEffect} from "../../../effects/pageData"
import MonthlyDataRow from "./MonthlyDataRow"
import {Button} from "react-bootstrap"
import {saveMixedChanges} from "../../../services/pageData"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import {RowLock} from "./RowLock"

function EditMonthlyData(props) {
  const { token, askDeleteLot, deleteRowById } = props
  const [lots, setLots] = useState([])
  const [addedLots, setAddedLots] = useState([])
  const [editedLots, setEditedLots] = useState([])
  const [rowsLocked, setRowsLocked] = useState(true)

  const save = () => {
    Promise.all(saveMixedChanges(token, addedLots, editedLots)).then(() => {
      setAddedLots([])
      setEditedLots([])
      allPageDataEffect(token, setLots, 'monthly')()
    })
    .catch(console.error)
  }

  const deleteRow = (lot) => {
    askDeleteLot(lot.lotAddress? lot.lotAddress : 'this location', () => {
      if (lot.id) {
        deleteRowById(lot.id)
        if (editedLots.map(l => l.id).includes(lot.id)) {
          setEditedLots(editedLots.filter(l => l.id !== lot.id))
        }
      } else {
        if (addedLots.map(l => l.id).includes(lot.id)) {
          setAddedLots(addedLots.filter(l => l.id !== lot.id))
        }
      }
      setLots(lots.filter(l => lot.id !== l.id))
    })
  }

  // eslint-disable-next-line
  useEffect(allPageDataEffect(token, setLots, 'monthly'), [])

  return (<div className="monthly-data">
    <h3>Monthly Lot Data</h3>
    <div className="flex-table">
      <div className="flex-table-row">
        <div className="flex-table-cell flex-table-header flex-table-cell-md">Letter<br/>Number</div>
        <div className="flex-table-cell flex-table-header">Address</div>
        <div className="flex-table-cell flex-table-header">Address Note</div>
        <div className="flex-table-cell flex-table-header flex-table-cell-md">Monthly Cost</div>
        <div className="flex-table-cell flex-table-header">Note</div>
        <div className="flex-table-cell flex-table-header">Urgent Note</div>
        <div className="flex-table-cell flex-table-header flex-table-cell-md">Control</div>
      </div>

      <DragDropContext onDragEnd={(result) => {
        const items = Array.from(lots)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        const numItems = items.length
        for (let i=0; i<numItems; i++) {
          items[i].displayOrder = i
        }
        setEditedLots(items)
        setLots(items)
      }}>

        <Droppable droppableId="monthly-parking-lots">
          {(provided) => (
            <div className="droppable-row" {...provided.droppableProps} ref={provided.innerRef}>
              {lots.map((l, index) =>
                <Draggable className="draggable-row" key={`monthly-${l.id}`} draggableId={`monthly-${l.id}`} index={index}>
                  {(provided) =>  rowsLocked ?
                    (<MonthlyDataRow lot={l} key={`monthly-${l.id}`}
                                     addedLots={addedLots} setAddedLots={setAddedLots}
                                     editedLots={editedLots} setEditedLots={setEditedLots} deleteRow={deleteRow} />) :
                  (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <MonthlyDataRow lot={l} key={`monthly-${l.id}`}
                                     addedLots={addedLots} setAddedLots={setAddedLots}
                                     editedLots={editedLots} setEditedLots={setEditedLots} deleteRow={deleteRow} />
                  </div>)
                  }</Draggable>)}
              {provided.placeholder}
            </div>)}
        </Droppable>
      </DragDropContext>
      <div className="flex-table-row">
        <div className="flex-table-cell flex-table-header">
          <Button onClick={() => setLots([
            ...lots,
            {
              lotLetter: '', lotNumber: '', lotAddress: '', addressNote: '', pageType: 'monthly',
              note: '', urgentNote: '',
              id: (Math.abs(lots.map(l => l.id).reduce((pv, cv) => cv < pv ? cv : pv)) * -1) - 1,
              displayOrder: 1 + lots.map(l => l.displayOrder).reduce((pv, cv) => cv>pv? cv : pv)
            }
          ])}><mwc-icon>add</mwc-icon> Add Row</Button>
        </div>
        <RowLock rowsLocked={rowsLocked} setRowsLocked={setRowsLocked} additionalClassNames={"flex-table-header"} />
        <div className="flex-table-cell flex-table-header">
          <Button onClick={save}><mwc-icon>save</mwc-icon> Save Changes</Button>
        </div>
      </div>
    </div>
  </div>)
}

export default EditMonthlyData
