import {getAllPageData, getPageData} from "../services/pageData";

export function pageDataEffect(setContent, page) {
  return () => {
    let mounted = true;
    getPageData(page)
      .then(content => {
        if (mounted) {
          setContent(content)
        }
      })
    return () => mounted = false;
  }
}

export function allPageDataEffect(token, setContent, page) {
  return () => {
    let mounted = true;
    getAllPageData(token, page)
      .then(content => {
        if (mounted) {
          setContent(content)
        }
      })
    return () => mounted = false;
  }
}