import './service.css'
import React, {useEffect, useState} from "react"
import {pageDataEffect} from "../../effects/pageData"
import '@material/mwc-icon'

function ShortTermParking() {
  const [content, setContent] = useState([])

  // eslint-disable-next-line
  useEffect(pageDataEffect(setContent, 'short'), [])

  return (
    <div>
      <h3>Short Term Parking</h3>
      <div className="short-term-parking">
        <div className="map-container">
          <iframe src="https://www.google.com/maps/d/embed?mid=1dcQwPtyxqyoekOmCT-bj2H1pq3M"
                  title="Short Term Parking Map" width="480" height="480"/>
          <p><span className="hourly-rates"><mwc-icon>hourglass_full</mwc-icon></span> = Hourly Rates Available</p>
          <p><span className="flat-rate"><mwc-icon>local_atm</mwc-icon></span> = Flat Rate Parking</p>
          <p><span className="limited-hours"><mwc-icon>av_timer</mwc-icon></span> = Limited Hours of Availability</p>
          <p><span className="text-to-pay">
              <mwc-icon>sms</mwc-icon>
            </span> = Text To Pay<br/> At these locations you can pay for your parking with your phone using your
            credit/debit card once your arrive at the parking location. Once you are parked, see the text to pay
            instructions on the signs located throughout the lot.
          </p>
        </div>
        <div className="table-container">
          <div className="flex-table">
            {content.map(row => (<div key={row.id} className="flex-table-row">
              <div className="flex-table-cell">{row.lotLetter && `${row.lotLetter})`}&nbsp;{row.lotAddress}&nbsp;(#{row.lotNumber})&nbsp;{row.textToPay && (
                <span>
                  <span className="text-to-pay"><mwc-icon>sms</mwc-icon></span>
                  <div className="text-to-pay-tooltip">Text To Pay Available Here!</div>
                </span>)}
                {row.hourlyRates && (<span><span className="hourly-rates"><mwc-icon>hourglass_full</mwc-icon></span>
            <div className="hourly-rates-tooltip">Hourly Rates Available Here!</div></span>)}
                {row.flatRates && (<span><span className="flat-rate"><mwc-icon>local_atm</mwc-icon></span>
                <div className="flat-rate-tooltip">Flat Rate Parking Available Here!</div></span>)}
                {row.limitedHours && (<span><span className="limited-hours"><mwc-icon>av_timer</mwc-icon></span>
                <div className="limited-hours-tooltip">Limited Hours of Availability!</div></span>)}
              </div>
              <div className="flex-table-cell">{row.addressNote}
                {row.note && <span className="blue-text">{row.note}</span>}
                {row.urgentNote && <span className="large-red-text">{row.urgentNote}</span>}
              </div>
            </div>))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortTermParking;
