import React, {useEffect, useState} from "react"
import {userDataEffect} from "../../effects/userData"
import {Button} from "react-bootstrap"
import {GlobalContext} from '../../App'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useCookies} from "react-cookie"
import {createUser} from "../../services/users";
import UserRow from "./components/UserRow";

function EditUsers() {
  const [users, setUsers] = useState([])
  const [showAddRow, setShowAddRow] = useState(false)
  const [cookies] = useCookies()

  const [newUser, setNewUser] = useState({
    email: '',
    welcomeImageUrl: ''
  })

  const addUser = () => {
    createUser(cookies.Authentication, newUser)
      .then(r => {
        setUsers([
          ...users,
          r
        ])
        setNewUser({
          email: '',
          welcomeImageUrl: ''
        })
        setShowAddRow(false)
      })
  }

  // eslint-disable-next-line
  useEffect(userDataEffect(setUsers, cookies.Authentication), [])

  return (
    <GlobalContext.Consumer>
      {context => (
        <div className="admin-users">
          <h2>Edit Users</h2>
          <div className="flex-table">
            <div className="flex-table-row">
              <div className="flex-table-cell flex-table-header">Email Address</div>
              <div className="flex-table-cell flex-table-header">Welcome Image Url</div>
              <div className="flex-table-cell flex-table-header flex-table-cell-sm">Buttons</div>
            </div>
            {users.length && users.map(u => (
              <UserRow user={u} token={cookies.Authentication} loggedInUserId={context.userId}
                       users={users} setUsers={setUsers}/>
            ))}
            {showAddRow && (
              <div id="new-user-row" className="flex-table-row hide">
                <div className="flex-table-cell"><input maxLength='100' onChange={event => setNewUser({
                  ...newUser,
                  email: event.target.value
                })} value={newUser.email}/></div>
                <div className="flex-table-cell"><input maxLength='500' onChange={event => setNewUser({
                  ...newUser,
                  welcomeImageUrl: event.target.value
                })} value={newUser.welcomeImageUrl}/></div>
                <div className="flex-table-cell flex-table-cell-sm">
                  <Button onClick={addUser}>
                    <mwc-icon>save</mwc-icon>
                  </Button>
                </div>
              </div>)}
            {!showAddRow && (
              <div className="flex-table-row">
                <div className="flex-table-cell"><Button onClick={() => setShowAddRow(true)}>
                  <mwc-icon>add</mwc-icon>
                </Button></div>
              </div>
            )}
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  )
}

export default EditUsers
