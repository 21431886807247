import React from "react"
import EmailLink from "../components/EmailLink"

function Service() {
  return (
    <div className="Service">
      <h3>Service</h3>
      <p>
        Palmetto Parking operates around 50 properties in South Carolina. The scope of our parking operations
        include, but are not limited to the following:
        <ul>
          <li>Surface Lot Management</li>
          <li>Garage Parking Management</li>
          <li>Special Event Parking Management</li>
          <li>Municipal Parking Management</li>
          <li>Facility Maintenance Services</li>
          <li>Hospital Valet/Self-Park Management</li>
          <li>Restaurant Valet Services</li>
          <li>University Parking Management</li>
          <li>Hotel Valet/Self-Park Management</li>
          <li>Vending spaces for food trucks, retailers, etc.</li>
          <li>Construction insight and consulting for garages and parking areas</li>
          <li>Parking attendants or valets for your event</li>
        </ul>
      </p>
      For more information, please email <EmailLink email="info@palmettoparking.com"/>.
    </div>
  )
}

export default Service
