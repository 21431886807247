import React, {useState} from "react"
import {deleteUser, modifyUser} from "../../../services/users";
import {Button} from "react-bootstrap";
import {confirmAlert} from "react-confirm-alert";

function UserRow(props) {
  const {user, token, loggedInUserId, users, setUsers} = props
  const [newWelcomeImage, setWelcomeImage] = useState(user.welcomeImageUrl)

  const askDeleteUser = (userId, email) => {
    const options = {
      title: 'Delete User',
      message: `Are you sure you want to delete ${email}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteUser(token, userId).then(() => setUsers(users.filter(u => u.id !== userId)),
              err => alert(err))
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    }

    confirmAlert(options)
  }

  return (
    <div key={user.id} className="flex-table-row">
      <div className="flex-table-cell">{user.email}</div>
      <div className="flex-table-cell">{loggedInUserId === user.id ?
        (<input maxLength='500' value={newWelcomeImage} className="flex-input"
                onChange={event => setWelcomeImage(event.target.value)}/>) :
        user.welcomeImageUrl
      }</div>
      <div className="flex-table-cell flex-table-cell-sm">
        {loggedInUserId !== user.id ? (
          <Button className="btn-danger" onClick={() => askDeleteUser(user.id, user.email)}>
            <mwc-icon>delete</mwc-icon>
          </Button>
        ) : (
          <Button onClick={() => modifyUser(token, user.id, newWelcomeImage).then(console.log)}>
            <mwc-icon>save</mwc-icon>
          </Button>
        )}
      </div>
    </div>
  )
}

export default UserRow