import React, {useEffect, useState} from 'react'
import {faqAdminDataEffect} from "../../effects/faqData"
import EditableFaqEntry from "./components/EditableFaqEntry";
import {useCookies} from "react-cookie";
import {RowLock} from "./components/RowLock";
import {Button} from "react-bootstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {updateFAQ} from "../../services/faqs";

function EditFaq() {
  const [cookies] = useCookies()
  const token = cookies.Authentication
  const [faqs, setFAQs] = useState([])
  const [reloadFlag, setReloadFlag] = useState(false)
  const [rowsLocked, setRowsLocked] = useState(true)

  useEffect(faqAdminDataEffect(token, setFAQs), [reloadFlag])

  return (<div className="admin-faq">
    <h2>Edit FAQ Data</h2>
    <div className="flex-table">
      <div className="flex-table-row faq-row">
        <div className="flex-table-cell flex-table-cell-md flex-table-header"><h4>Question</h4></div>
        <div className="flex-table-cell flex-table-header"><h4>Answer</h4></div>
        <div className="flex-table-cell flex-table-cell-md flex-table-header"><h4>Controls</h4></div>
      </div>

      <DragDropContext onDragEnd={(result) => {
        const items = Array.from(faqs)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        const numItems = items.length
        for (let i = 0; i < numItems; i++) {
          items[i].displayOrder = i
          updateFAQ(token, items[i])
        }
        setFAQs(items)
      }}>
        <Droppable droppableId="faqs">
          {(provided) => (
            <div className="droppable-faq-row" {...provided.droppableProps} ref={provided.innerRef}>
              {faqs.map((faq, index) =>
                <Draggable className="draggable-row" key={`faq-${faq.id}`} draggableId={`faq-${faq.id}`} index={index}>
                  {(provided) => rowsLocked ?
                    (<EditableFaqEntry token={token} faq={faq} reload={() => setReloadFlag(!reloadFlag)}/>) :
                    (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <EditableFaqEntry token={token} faq={faq} reload={() => setReloadFlag(!reloadFlag)}/>
                    </div>)
                  }</Draggable>)}
              {provided.placeholder}
            </div>)}
        </Droppable>
      </DragDropContext>

      <div className="flex-table-row faq-row">
        <div className="flex-table-cell flex-table-cell-md">
          <div>
            <Button onClick={() => setFAQs([...faqs, {
              question: '',
              answer: '',
              active: true,
              displayOrder: faqs.map(f => f.displayOrder).reduce((previousValue, currentValue) => previousValue < currentValue ? currentValue : previousValue) + 1
            }])}>Add FAQ</Button>
          </div>
        </div>
        <div className="flex-table-cell">&nbsp;</div>
        <RowLock rowsLocked={rowsLocked} setRowsLocked={setRowsLocked} additionalClassNames="flex-table-cell-md"/>
      </div>
    </div>
  </div>)
}

export default EditFaq
