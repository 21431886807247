import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react"
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import About from "./pages/About"
import Service from "./pages/Service/Service"
import ShortTermParking from "./pages/Service/ShortTerm"
import Jobs from "./pages/Staff/Jobs"
import Contact from "./pages/Contact"
import FAQ from "./pages/FAQ"
import {useCookies} from "react-cookie"
import EditUsers from "./pages/Admin/EditUsers"
import EditLotData from "./pages/Admin/EditLotData"
import {Image} from "react-bootstrap"
import BASE_URL from "./services/const";
import EditFaq from "./pages/Admin/EditFaq";

const initialState = {
  loggedIn: false,
  displayName: '',
  userId: '',
  welcomeUrl: ''
}

export const GlobalContext = React.createContext(initialState)

function App() {
  const [cookies, removeCookie] = useCookies()
  const state = {
    loggedIn: cookies.Authentication && cookies.Authentication !== '',
    displayName: cookies.DisplayName,
    userId: Number.parseInt(cookies.UserId),
    welcomeUrl: cookies.WelcomeImage && cookies.WelcomeImage !== '' ?
      cookies.WelcomeImage :
      'https://memegenerator.net/img/instances/72865796/your-access-has-been-granted.jpg'
  };

  function logout() {
    removeCookie('Authentication', '')
    removeCookie('DisplayName', '')
    removeCookie('UserId', '')
    removeCookie('WelcomeImage', '')
  }

  return (
    <GlobalContext.Provider value={state}>
      <div className="App">
        <header className="App-header">
          <div className="header-outer-container">
            <div className="header-inner-container">
              <div className="header-logo"><img alt="logo" src="/logo.jpg"/></div>
              <div className="header-link-container">
                <div className="header-links">
                  <Navbar collapseOnSelect expand="md" className="justify-content-center bg-override">
                    <Navbar.Toggle/>
                    <Navbar.Collapse className="justify-content-center">
                      <Nav>
                        <Nav.Link href="/#/about">About</Nav.Link>
                        <NavDropdown id="service" title="Service">
                          <NavDropdown.Item href="/#/service">All
                            Services</NavDropdown.Item>
                          <NavDropdown.Item href="https://www.parkerbill.com/landing?EQS=hzbd9YCwOvmyhWhzm1bGeg%3D%3D">Monthly
                            Parking</NavDropdown.Item>
                          <NavDropdown.Item href="/#/service/short">Short Term
                            Parking</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown id="staff" title="Staff">
                          <NavDropdown.Item href="/#/staff/jobs">Work for Us</NavDropdown.Item>
                          {!state.loggedIn && (
                          <NavDropdown.Item href={`${BASE_URL}/google/redirect`}>Admin
                            Login</NavDropdown.Item>
                          )}
                        </NavDropdown>
                        <Nav.Link href="/#/faq">FAQ</Nav.Link>
                        <Nav.Link href="/#/contact">Contact</Nav.Link>
                        {state.loggedIn && (
                          <NavDropdown id="admin" title="Admin User">
                            <Nav.Link href="/#/admin/users">Add/Remove Admin Users</Nav.Link>
                            <Nav.Link href="/#/admin/data">Edit Parking Lot Data</Nav.Link>
                            <Nav.Link href="/#/admin/faq">Edit FAQ</Nav.Link>
                            {/*<Nav.Link href="/#/admin/forms">Upload Forms</Nav.Link>*/}
                            <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                          </NavDropdown>)}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content-background">
            <div className="main-content-box">
              <div className="main-content-body">
                <Router>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/about"/>
                    </Route>
                    <Route exact path="/about">
                      <About/>
                    </Route>
                    <Route exact path="/service">
                      <Service/>
                    </Route>
                    <Route exact path="/service/short">
                      <ShortTermParking/>
                    </Route>
                    <Route exact path="/staff/jobs">
                      <Jobs/>
                    </Route>
                    <Route exact path="/faq">
                      <FAQ/>
                    </Route>
                    <Route exact path="/contact">
                      <Contact/>
                    </Route>
                    <Route exact path="/admin">
                      {state.loggedIn ?
                        (<div className="admin-landing">
                          <h1>Welcome, {state.displayName}!</h1>
                          <Image src={state.welcomeUrl}/>
                        </div>) :
                        (<div>You must be logged in to access the admin area.</div>)}
                    </Route>
                    <Route exact path="/admin/users">
                      {state.loggedIn ? (<EditUsers/>) : (<Redirect to="/admin"/>)}
                    </Route>
                    <Route exact path="/admin/data">
                      {state.loggedIn ? (<EditLotData/>) : (<Redirect to="/admin"/>)}
                    </Route>
                    <Route exact path="/admin/faq">
                      {state.loggedIn ? (<EditFaq/>) : (<Redirect to="/admin"/>)}
                    </Route>
                    {/*<Route exact path="/admin/forms">*/}
                    {/*  {state.loggedIn ? (<EditForms/>) : (<Redirect to="/admin"/>)}*/}
                    {/*</Route>*/}
                    <Route path="*">
                      <div className="four04">
                        <h1>Sorry, couldn't find a parking space!</h1>
                        <p>404: Page not found</p>
                      </div>
                    </Route>
                  </Switch>
                </Router>
              </div>
            </div>
          </div>
        </header>
      </div>
    </GlobalContext.Provider>
  )
}

export default App
