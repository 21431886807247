import BASE_URL from "./const";

export function getUsers(token) {
  return fetch(`${BASE_URL}/users`, {
    headers: {
      'Accept': 'application/json',
      "authorization": `Bearer ${token}`
    }
  }).then(data => data.json())
}

export function createUser(token, user) {
  return fetch(`${BASE_URL}/users`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify(user)
  }).then(data => data.json())
}

export function modifyUser(token, userId, welcomeImageUrl) {
  return fetch(`${BASE_URL}/users/${userId}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    body: JSON.stringify({welcomeImageUrl})
  }).then(data => data.json())
}

export function deleteUser(token, userId) {
  return fetch(`${BASE_URL}/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      "authorization": `Bearer ${token}`
    }
  }).then(data => data.json())
}
