import React from "react";

function About() {
  return (
      <div className="About">
        <h1>We Are Your Parking Solution</h1>
        <p>
          Palmetto Parking was founded in 2003 at the request of local property owners looking for an honest,
          service-based alternative to national parking conglomerates.  Owned and operated by Charleston locals,
          Palmetto Parking presently operates around 50 properties in South Carolina.  The properties consist of
          multi-level decks, surface lots, valet parking, event parking, and municipal operations.
        </p>
        <p>
          Since its founding, Palmetto Parking has operated every type of parking scenario imaginable.
          The emphasis and driving force behind every operation is service.
          Regardless of the demands and expectations required, Palmetto Parking has proven successful delivering
          customers and clients the highest quality of service and integrity available in the parking industry.
        </p>
        <p>For answers to frequently asked questions, please click <a href="/#/faq">here</a>.</p>
      </div>
    );
}

export default About;
