import React from "react"
import EmailLink from "../components/EmailLink"

function Jobs() {
  return (
    <div className="Jobs">
      <h3>Work for Us</h3>
      <p>
        We are always looking for new, positive personalities to join our organization. Our goal is to
        provide the ultimate in customer service and satisfaction while offering our employees a place
        to grow. Our positions offer flexible scheduling to suit your needs and a competitive
        compensation package.
      </p>
      <p>
        If you are interested in pursuing employment with Palmetto Parking, please visit our booth
        attendant at the 69 Wentworth Street garage to pick up and turn in an application. Our Office
        Manager will review your application and give you a call when a position is available.
        You may also download the application from the link below, complete it and email it to:
        <EmailLink email="info@palmettoparking.com"/>.
      </p>
      <p className="disclaimer">
        Palmetto Parking is an equal-opportunity affirmative-action employer M/F/D/V. All applicants
        will be considered for employment without attention to race, color, religion, sex, sexual
        orientation, gender identity, national origin, veteran, or disability status.
      </p>
      <p>
        Download <a href="/forms/PalmettoParkingEmploymentApplication.pdf">Palmetto Parking Employment Application</a>
      </p>
    </div>
  )
}

export default Jobs
