import React, {useState} from "react"
import MarkdownEditor from "@uiw/react-markdown-editor"
import {Button} from "react-bootstrap"
import {addFAQ, deleteFAQ, updateFAQ} from "../../../services/faqs"
import {confirmAlert} from "react-confirm-alert"
import Moment from 'react-moment'
import 'moment-timezone'

function EditableFaqEntry(props) {
  const faq = props.faq
  const newEntry = faq.id === undefined
  const saveFAQ = newEntry ? addFAQ : updateFAQ
  const [editing, setEditing] = useState(false)
  const [editedQ, setEditedQ] = useState(faq.question)
  const [editedA, setEditedA] = useState(faq.answer)

  const confirmDeleteFAQ = (deleteAction) => {
    const options = {
      title: 'Delete FAQ Entry',
      message: `Are you sure you want to delete this entry?`,
      buttons: [
        {
          label: 'Yes',
          onClick: deleteAction
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    }
    confirmAlert(options)
  }

  return editing || newEntry ?
    (<div className="flex-table-row faq-row">
      <div className="flex-table-cell flex-table-cell-md">
        <div>
          <input type='text' value={editedQ} onChange={(event) => setEditedQ(event.target.value)}/>
        </div>
      </div>
      <div className="flex-table-cell">
        <div>
          <MarkdownEditor value={editedA} onChange={(value) => setEditedA(value)}/>
        </div>
      </div>
      <div className="flex-table-cell flex-table-cell-md">
        <div>
          {newEntry ? (<div></div>) : (<Button variant="warning" onClick={() => updateFAQ(props.token, {
            ...faq,
            updated: new Date(),
            active: !faq.active
          }).then(props.reload)}>{faq.active ? "Dea" : "A"}ctivate</Button>)}
          <Button onClick={() => saveFAQ(props.token, {
            ...faq,
            question: editedQ,
            answer: editedA,
            updated: new Date()
          })
            .then(props.reload).then(() => setEditing(false))}>
            <mwc-icon>save</mwc-icon>
          </Button>
          {newEntry ? (<div></div>) : (<Button variant="danger" onClick={() => setEditing(false)}>Cancel</Button>)}
        </div>
      </div>
    </div>) :
    (<div className="flex-table-row faq-row">
      <div className={"flex-table-cell flex-table-cell-md" + (faq.active ? "" : " inactive-faq")}><b>{faq.question}</b>
      </div>
      <div className={"flex-table-cell" + (faq.active ? "" : " inactive-faq")}>
        <MarkdownEditor.Markdown source={faq.answer}/>
      </div>
      <div className="flex-table-cell flex-table-cell-md">
        <div>
          <Button variant="warning" onClick={() => updateFAQ(props.token, {
            ...faq,
            updated: new Date(),
            active: !faq.active
          }).then(props.reload)}>{faq.active ? "Dea" : "A"}ctivate</Button>&nbsp;
          <Button onClick={() => setEditing(true)}>
            <mwc-icon>edit</mwc-icon>
          </Button>&nbsp;
          <Button variant="danger" onClick={() => confirmDeleteFAQ(() => deleteFAQ(props.token, faq.id)
            .then(props.reload))}>
            <mwc-icon>delete</mwc-icon>
          </Button>
          <br />
          <hr />
          <div>
            Updated:<br/>
            <Moment tz='America/New_York' format="MMMM Do YYYY, h:mm:ss a">{faq.updated}</Moment>
          </div>
        </div>
        <div className='faq-order'>{faq.displayOrder}</div>
      </div>
    </div>)
}

export default EditableFaqEntry
