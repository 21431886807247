import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import EditShortTermData from "./components/EditShortTermData"
import EditMonthlyData from "./components/EditMonthlyData"
import {confirmAlert} from "react-confirm-alert"
import {deletePageData} from "../../services/pageData"
import {useCookies} from "react-cookie"

function EditLotData() {
  const [cookies] = useCookies()
  const token = cookies.Authentication

  const askDeleteLot = (lotAddress, yesAction) => {
    const options = {
      title: 'Delete Parking Lot',
      message: `Are you sure you want to delete the parking lot at ${lotAddress}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: yesAction
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    }
    confirmAlert(options)
  }

  const deleteRowById = (id) => {
    deletePageData(token, id).then(console.log)
  }

  return (<div className="admin-data">
    <h2>Edit Lot Data</h2>
      <Tabs>
        <TabList>
          <Tab>Short Term Parking</Tab>
          <Tab>Monthly Parking</Tab>
        </TabList>
        <TabPanel>
          <EditShortTermData token={token} askDeleteLot={askDeleteLot} deleteRowById={deleteRowById}/>
        </TabPanel>
        <TabPanel>
          <EditMonthlyData token={token} askDeleteLot={askDeleteLot} deleteRowById={deleteRowById}/>
        </TabPanel>
      </Tabs>
  </div>)
}

export default EditLotData
