import React from "react";
import EmailLink from "./components/EmailLink";

function Contact() {
  return (
      <div className="Contact">
        <h3>Contact Us</h3>
        <h5>Office Address</h5>
        <p>
          1756 Central Park Road<br />
          Charleston, SC 29412
        </p>
        <h5>Office Hours</h5>
        <p>
          Monday and Friday - 8am to 3 pm<br />
          Tuesday thru Thursday - 8am to 4pm
        </p>
        <h5>Mailing Address</h5>
        <p>
          PO Box 12609<br />
          Charleston, SC  29422
        </p>
        <p><b>Phone:</b> <a href="tel:843-958-9556">843-958-9556</a></p>
        <p><b>Email:</b> <EmailLink email="info@palmettoparking.com" /></p>
      </div>
    );
}

export default Contact;
