import {getUsers} from "../services/users";

export function userDataEffect(setUsers, token) {
  return () => {
    let mounted = true;
    getUsers(token)
      .then(users => {
        if (mounted) {
          setUsers(users)
        }
      }, err => {
        console.error(err)
      })
    return () => mounted = false;
  }
}